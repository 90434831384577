import Image from "next/image";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { Background, Parallax } from "react-parallax";

import { ephesis } from "@/utils/fonts";
import { useNearby } from "@/hooks/useNearby";
import useBreakpoints from "@/hooks/useBreakpoints";

const Nearby = () => {
  const { tablet: tab2 } = useNearby();
  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={300}>
      <Background className={"parallaxImageGf"}>
        <Image
          alt="Nearby parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/corazon_mobile.webp"
              : "/mar_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Box
        height={tab2 ? "80vh" : "175vh"}
        display={"flex"}
        flexDirection={tab2 ? "row" : "column"}
        justifyContent={"center"}
        padding={"1vh"}
        alignItems={"center"}
        gap={"1vh"}
      >
        <Card className="glass">
          <CardHeader
            title="Hotel Rojo Manglar"
            titleTypographyProps={{
              sx: {
                textAlign: "center",
                color: "var(--color-primary-4)",
                fontSize: {
                  xs: "6.5vw",
                  tablet: "4vw",
                  md: "3.5vw",
                  lg: "2.5vw",
                },
              },
              className: ephesis.className,
            }}
          />
          <CardContent
            sx={{ alignSelf: "center", width: "100%", height: "100%" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6433.284286445646!2d-99.98033888603334!3d16.906537125387644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85caf97a28f60a55%3A0xef14ab0013b859e4!2sHotel%20Rojo%20Manglar!5e0!3m2!1ses-419!2smx!4v1681884658096!5m2!1ses-419!2smx"
              style={{
                border: 0,
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
              loading="lazy"
              title="Rojo Manglar"
            ></iframe>
          </CardContent>
        </Card>

        <Card className="glass">
          <CardHeader
            title="Hotel Evana"
            titleTypographyProps={{
              sx: {
                textAlign: "center",
                color: "var(--color-primary-4)",
                fontSize: {
                  xs: "6.5vw",
                  tablet: "4vw",
                  md: "3.5vw",
                  lg: "2.5vw",
                },
              },
              className: ephesis.className,
            }}
          />
          <CardContent
            sx={{ alignSelf: "center", width: "100%", height: "100%" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6433.284286445646!2d-99.98033888603334!3d16.906537125387644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85caf97d4b84d39b%3A0x386bfc0613bd470!2sHotel%20Evana!5e0!3m2!1ses-419!2smx!4v1681884830646!5m2!1ses-419!2smx"
              style={{
                border: 0,
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
              loading="lazy"
              title="Evana"
            ></iframe>
          </CardContent>
        </Card>

        <Card className="glass">
          <CardHeader
            title="Villa Bonita"
            titleTypographyProps={{
              sx: {
                textAlign: "center",
                color: "var(--color-primary-4)",
                fontSize: {
                  xs: "6.5vw",
                  tablet: "4vw",
                  md: "3.5vw",
                  lg: "2.5vw",
                },
              },
              className: ephesis.className,
            }}
          />
          <CardContent
            sx={{ alignSelf: "center", width: "100%", height: "100%" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6433.517527306109!2d-99.97905190072473!3d16.899701461868005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85caf97bd642e327%3A0xd18e583fc7cfbd0b!2sVilla%20Bonita!5e0!3m2!1ses-419!2smx!4v1681886798774!5m2!1ses-419!2smx"
              style={{
                border: 0,
                borderRadius: "15px",
                width: "100%",
                height: "100%",
              }}
              loading="lazy"
              title="Villa Bonita"
            ></iframe>
          </CardContent>
        </Card>
      </Box>
    </Parallax>
  );
};

export default Nearby;
