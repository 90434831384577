import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const useNearby = () => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up('tablet'));
  
  return {
    tablet
  }
}